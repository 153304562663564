const ALL_STEPS = [
  {
    "element": "#cycle_panel",
    "originalTitle": "Project cycle",
    "popover": {
      "className": "popover-class",
      "description": `
      <p>
      In Shape Up, the iteration cycle usually goes for 6-8 weeks to allow time for meaningful features to be completed.
      </p>

      <p><b>To proceed:</b> Set the project's start date so you can track where you are in the timeline.</p>
      `,
      "showButtons": true
    },
    // "customBehavior": "clickToNext"
  },
  {
    // "element": "#default_project",
    "element": "#project_list",
    "originalTitle": "Project",
    "popover": {
      "className": "popover-class",
      "description": `
      <p>A default project has been created for this Trello board.</p>

      <p>
        While you have the option to add more projects to the board, for simplicity it is recommended
        to dedicate a Trello board to one single project.
      </p>

      <p><b>Action:</b> Click a project.</p>
      `,
      // "showButtons": true
    }
  },
  {
    "element": "#chart_panel",
    "originalTitle": "Hill Chart",
    "popover": {
      "className": "popover-class",
      "description": `
      <p>Data points will be added automatically as new Scopes are created.</p>
      <p>As the uncertainties surrounding a Scope become clearer, move the Scope data point closer to the top of the hill.</p>
      <p>Create a snapshot to save the updated data points.</p>
      `,
      "showButtons": true
    },
    // "customBehavior": "clickToNext"
  },
  {
    "element": "#unscoped_column",
    "originalTitle": "Unscoped",
    "popover": {
      "className": "popover-class",
      "description": `
      <p>
        The 'Unscoped' column is intended for Tasks that currently lack logical grouping within the project.
      </p>

      <p>
        As the project direction becomes clearer, you can then add more explicit Scopes.
      </p>

      <p><b>Action:</b> Add a Task.</p>
      `,
      "showButtons": true
    },

  },
]

ALL_STEPS.forEach((step, index) => {
  step.popover.title = `(${index + 1}/${ALL_STEPS.length}) ${step.originalTitle}`
})

export default class {
  static stepsForPage1(lastViewedProject, cycleProvided) {
    const steps = []

    // TODO: Consider keeping track of the completed step per user which is especially useful
    // for teams that use disposable projects.
    if (!cycleProvided) {
      steps.push(ALL_STEPS[0])
    } else {
      if (!lastViewedProject) {
        steps.push(ALL_STEPS[1])
      }
    }
    return steps
  }

  static stepsForPage2(project, t) {
    const steps = []
    const scopeCards = $tpu.r.scopes.cache(project, t).data
    if (scopeCards.length == 1) {
      const firstScope = scopeCards[0]

      // Not all projects have Unscoped as the creation of Unscoped card might fail.
      if (firstScope.name != "Unscoped") {
        return steps
      }

      if (firstScope.taskCardIds.length <= 0) {
        // Keep showing this until the user has started making Scope/Task cards.
        steps.push(ALL_STEPS[2])

        const step = Object.assign({}, ALL_STEPS[3], { element: `#scope_${firstScope.id}` })
        steps.push(step)
      }
    }

    return steps
  }

  static startTourForPage1(component, lastViewedProject, cycleProvided) {
    // const steps = this.stepsForPage1(lastViewedProject, cycleProvided)
    // this._startTour(component, steps)
  }

  static startTourForPage2(component, project, t) {
    // const steps = this.stepsForPage2(project, t)
    // this._startTour(component, steps)
  }

  static _startTour(component, steps) {
    // Always stop the previous tour first whether the previous tour was from a different page or from the same
    // page prior to reloading. This is because there might not be a tour to show next.
    this.stopPreviousTour(component)

    if (steps.length > 0) {
      // Allow time for the previous tour popover to close.
      setTimeout(() => {
        console.debug("Showing next tour")

        GLib.action.execute({
          "action": "tours/start",
          "options": {
            "allowClose": false
          },
          "startFrom": 0,
          "steps": steps
        }, component);
      }, 600)
    }
  }

  static stopPreviousTour(component) {
    GLib.action.execute({
      "action": "tours/stop",
    }, component);
  }
}
